export const topics = [
    {
        title: 'CANCELAMENTO DE VOO',
        body: 'De acordo com o artigo 12 da resolução nº 400/16 da ANAC, toda alteração no voo deve ser comunicada com no mínimo 72 horas de antecedência em relação ao horário originalmente estabelecido para o voo. Sendo comunicado o cancelamento em prazo inferior a 72 horas, o passageiro tem  direito  a, além  do  reembolso integral ou  realocação em  outro  voo, indenização por danos morais e materiais.',
    },
    {
        title: 'ATRASO DE VOO',
        body: 'São vários os motivos que fazem o voo atrasar: condições climáticas, manutenção da aeronave e readequação da malha aérea são alguns exemplos. Se a aeronave chegou em seu destino com 4 horas ou mais de atraso, você tem direito a uma indenização.',
    },
    {
        title: 'EXTRAVIO  DE  BAGAGEM',
        body: 'O  extravio  de  bagagem  é  o  pesadelo  da  maioria dos viajantes. O primeiro passo é fazer o Registro de Irregularidade de Bagagem (RIB) no guichê da companhia aérea no momento do embarque/desembarque. Conforme o artigo 12 da resolução nº 400/16 da ANAC, sua bagagem deve ser localizada em até 21 dias (em casos de voos internacionais) ou 7 dias (em casos de voos nacionais), sendo que após esse prazo é considerado extravio definitivo. Você  tem  direito  a  indenização  em  todos  os  casos  de extravio  temporário  ou definitivode bagagem.',
    },
    {
        title: 'OVERBOOKING',
        body: 'Overbooking significa  voo  superlotado,  ou  seja,  é  quando  a companhia  aérea  vende  mais  passagens  do  que  pode  oferecer.  Se  você  foi barrado de voar, tem direito a realocação em outro voo ou reembolso integral, além da indenização por danos morais e materiais.',
    },
    {
        title: 'NO-SHOW',
        body: 'O termo “no-show” significa não comparecimento. Em muitos casos, o  não  comparecimento  no  voo  de  ida  implica  no  cancelamento  automático  da passagem   de   volta. Nesse   caso   fique   atento, essa   prática   é ilegal e, independente  do  motivo,  a  empresa  não  pode  cancelar  a  passagem  de  volta. Caso isso ocorra você tem direito a indenização por danos morais e materiais.',
    },
    {
        title: 'PERDA DE CONEXÃO',
        body: 'Se a conexão perdida gerar atraso de 4 horas ou mais para  sua chegada  ao  destino final,  você tem  direito  a  indenização  por  danos morais e materiais.',
    },
];