export const costumers = [
    {
        comment: 'Sou cabelereiro e viajo muito para me apresentar em Workshops neste seguimento. São incontáveis as vezes que a HELP PASSENGERS me socorreu, 24 horas por dia, 7 dias por semana, eu posso contar com eles!',
        name: 'Nell Carmo',
        picture: 'assets/img/customers/nell_carmo.jpg',
    },
    {
        comment: 'O atendimento deles é 100% online, inclusive a assinatura de contratos. Sempre viajo, e sem sombra de dúvidas é o jeito mais fácil de reivindicar minha indenização quando tenho problema com meus voos.',
        name: 'Danielle Pigato',
        picture: 'assets/img/customers/danielle_pigato.jpg',
    },
    {
        comment: 'Eu nunca imaginei ser indenizado por problemas com voos. Já havia passado por situações análogas antes, porém só depois que conheci a HELP PASSENGERS passei a ser indenizado. A equipe é muito atenciosa e solícita, sempre conscientizando os passageiros de seus direitos.',
        name: 'Eduardo Giostri',
        picture: 'assets/img/customers/eduardo_giostri.jpg',
    },
    {
        comment: 'Atendimento rápido e fácil, sem burocracia. Em menos de 2 meses a companhia aérea já havia efetuado proposta de acordo. Sinto mais seguro em viajar e saber que sempre haverá uma equipe de plantão para me ajudar caso necessário!',
        name: 'Mateus Iaroczinski Turek',
        picture: 'assets/img/customers/mateus_turek.jpg',
    },
];