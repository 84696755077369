export const questions = [
    {
        ask: 'Eu tenho que pagar para dar entrada no meu pedido de indenização?',
        answer: 'Não se preocupe, a HELP PASSENGERS arca com os possíveis custos iniciais do processo e você só paga se ganhar! Cobramos pelo nosso serviço uma taxa de 30% apenas em casos de êxito, ou seja, nós só recebemos se você receber a   indenização!'
    },
    {
        ask: 'Como sei que tenho direito a uma indenização?',
        answer: 'É  rápido e  gratuito descobrir  se  você  tem  direito  a  uma  indenização,  relate  o ocorrido através  do  nosso WhatsApp, E-mail ou  Facebook,  e  com  a  maior brevidade a nossa equipe lhe retornará o contato com um parecer acerca dos seus direitos'
    },
    {
        ask: 'Vou ter que pagar alguma coisa se não ganhar uma indenização?',
        answer: 'Caso  seu pedido de  indenização  seja negado, a  HELP PASSENGERS arcará com todas as eventuais despesas.'
    },
    {
        ask: 'Não moro no Brasil, vocês podem me ajudar?',
        answer: 'Sim, podemos te ajudar desde que você possua um comprovante de residência brasileiro e que o voo tenha sido operado por uma companhia aérea com registro no Brasil.'
    }
];