import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
    selector: 'app-signup',
    templateUrl: './signup.component.html',
    styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
    deviceInfo = null;
    isMobile: Boolean = false;
    test : Date = new Date();
    focus;
    focus1;
    focus2;
    customerName;
    customerEmail;
    message;
    phoneNumber;

    constructor( private deviceService: DeviceDetectorService) {
        this.checkDeviceType();
     }

    ngOnInit() {
        this.customerName = '';
        this.customerEmail = '';
        this.phoneNumber = '+5541996635645'; 
    }

    checkDeviceType() {
        this.deviceInfo = this.deviceService.getDeviceInfo();
        this.isMobile = this.deviceService.isMobile();
    }
    
    setMessage() {

        let name = this.customerName !== '' ? `Oi, me chamo ${this.customerName.toUpperCase()}, solicito contato.` : `Olá, solicito contato.`;
        let email = this.customerEmail !== ''? `Meu email é ${this.customerEmail}.` : ``;
        this.message = encodeURI(`${name} ${email}`);
    }
}
